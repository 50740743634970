import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-no-permission-page',
  templateUrl: './no-permission.page.component.html',
  styleUrls: ['./no-permission.page.component.scss']
})
export class NoPermissionPageComponent {
  alertsLink = environment.activityFeedBase
 
}
