import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, combineLatest, iif, of } from 'rxjs';
import { filter, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { difference, groupBy } from 'lodash';

import { ECompanyLoginMode, PermissionsService } from '@zonar-ui/auth';
import { IDivision } from '@zonar-ui/auth/lib/models/company.model';
import { IUserProfile } from '@zonar-ui/auth/lib/models/user-profile.model';

import { AUTH_PERMISSIONS } from '@environments/shared';
import { ENV } from '@environments/environment.provider';
import { CompanyService } from './company-store.service';
import { environment } from '@environments/environment';

const adminPermPatternBuilder = (companyId: string) => `:${companyId}:::${AUTH_PERMISSIONS.MANAGE_ALERTS}`;

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  isAnyAdmin$ = new ReplaySubject<boolean>(1);
  isManagedThresholds$ = new ReplaySubject<boolean>(1);

  adminThresholdProfiles$ = new ReplaySubject<Record<string, IUserProfile[]>>(1);

  constructor(
    @Inject(ENV) private env: any,
    private _httpClient: HttpClient,
    private _permissionsService: PermissionsService,
    private _companyService: CompanyService
  ) {}

  initialize(companyId: string) {
    this._permissionsService
      .getIsZonarUser()
      .pipe(switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfCompanyAdmin$(companyId))))
      .subscribe((isAdmin) => {
        this.isAnyAdmin$.next(isAdmin);
      });

    this.hasThresholdSettingsPermission(companyId).subscribe((isManagedThresholds) => {
      this.isManagedThresholds$.next(isManagedThresholds);
    });
  }

  getThresholdAdminProfiles(userId: string): Observable<Record<string, IUserProfile[]>> {
    const { applicationId, adminRoleId } = this.env.thresholdSettingsBase;
    return this._httpClient
      .get<IUserProfile[]>(
        `${this.env.coreEntityApiBase.url}/userprofiles/?userId=${userId}&applicationId=${applicationId}&roleId=${adminRoleId}`
      )
      .pipe(
        retry(3),
        map((userProfiles) => groupBy(userProfiles, 'companyId'))
      );
  }

  private hasThresholdSettingsPermission(companyId: string): Observable<boolean> {
    return this.adminThresholdProfiles$.pipe(map((mapperByCompanyId) => !!mapperByCompanyId[companyId]));
  }

  /**
   * If the user is a company admin, then they need to match these criteria in order to be granted admin permissions:
   * - For group policy context:
   * 1. `roles` array in needs to contain object which has `id` property equal to the adminRole parameter in the environment file
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   *
   * - For user policy context:
   * 1. `isAdminRole` value needs to be `true`
   * 2. `isLegacyDivisionsAll` value needs to be `true`
   * @param companyId the currently selected company on the UI
   */
  private checkIfCompanyAdmin$(companyId): Observable<boolean> {
    return this._getCompanyLoginMode().pipe(
      switchMap((loginMode) => {
        return iif(
          () => loginMode === ECompanyLoginMode.GROUP_POLICY,
          this._companyAdminGroupContextCheck(companyId),
          this._companyAdminUserContextCheck(companyId)
        );
      })
    );
  }

  private _companyAdminGroupContextCheck(companyId) {
    return this._permissionsService.getUserGroupPolicies().pipe(
      map((userPolicies) => {
        const allPoliciesForApplicationId = userPolicies.filter((userPolicy, i) => {
          return userPolicy.policy.grants.some((grant) => grant.application.id === environment.auth.applicationId);
        });
        const allPoliciesForCompanyId = allPoliciesForApplicationId.filter((userPolicy) => userPolicy.policy.companyId === companyId);
        const isAdminRolePresent = allPoliciesForCompanyId.some((policy) => {
          const grantForApp = policy.policy.grants.find((grant) => grant.application.id === environment.auth.applicationId);
          return grantForApp.roles.some((role) => role.id === environment.adminRole);
        });
        return isAdminRolePresent;
      })
    );
  }

  private _companyAdminUserContextCheck(companyId) {
    return combineLatest([
      this._permissionsService.hasPermission(adminPermPatternBuilder(companyId)),
      this.isLegacyDivisionsAll(companyId)
    ]).pipe(
      map(([isAdminRole, isLegacyDivisionsAll]) => {
        return isAdminRole && isLegacyDivisionsAll;
      })
    );
  }

  /**
   * Returns the company login mode for the currently selected company
   * @returns Observable of login mode for the currently selected company
   */
  private _getCompanyLoginMode(): Observable<ECompanyLoginMode> {
    return this._companyService.currentCompany$.pipe(
      filter((selectedCompany) => !!selectedCompany),
      switchMap((selectedCompany) => {
        return this._permissionsService.getCompanyLoginMode().pipe(
          filter((companies) => !!companies),
          map((companies) => {
            return companies.find((company) => selectedCompany.value === company.id).loginMode;
          })
        );
      })
    );
  }

  /**
   * Select current profile and check all divisions are LEGACY type
   *
   * @param companyId the currently selected company on the UI
   */
  private isLegacyDivisionsAll(companyId: string): Observable<boolean> {
    return combineLatest([this.getLegacyCompanyDivisionsByCompanyId(companyId), this.getProfileDivisions(companyId)]).pipe(
      map(([legacyCompanyDivisions, profileDivisions]) => {
        if (Array.isArray(profileDivisions)) {
          const profileDivisionsSet = new Set(profileDivisions);
          const legacyCompanyDivisionsSet = new Set(legacyCompanyDivisions);
          return profileDivisions.length === 0 || this._isSubset(profileDivisionsSet, legacyCompanyDivisionsSet);
        } else {
          return false;
        }
      })
    );
  }

  private _isSubset(setA, setB) {
    for (let elem of setB) {
      if (!setA.has(elem)) {
        return false;
      }
    }
    return true;
  }

  private getProfileDivisions(companyId: string) {
    return combineLatest([
      this._permissionsService.getCompanyMap(),
      this._permissionsService.getUserProfiles(),
      this._permissionsService.getUserGroupPolicies()
    ]).pipe(
      filter(([companyMap, userProfiles, policies]) => !!(companyMap && userProfiles && policies)),
      take(1),
      map(([companyMap, userProfiles, userPolicies]) => {
        const selectedCompany = companyMap[companyId] || {};
        if (selectedCompany.loginMode === ECompanyLoginMode.GROUP_POLICY) {
          const selectedPolicy = userPolicies.find(
            (userPolicy) =>
              userPolicy.policy.grants.find((grant) => grant.application.id === this.env.auth.applicationId) &&
              userPolicy.tenant.scope.companies.find((company) => company.id === companyId)
          );

          if (!selectedPolicy) {
            return selectedPolicy;
          }

          return selectedPolicy.tenant.scope.divisions.map((division) => division.id);
        } else {
          const selectedProfiles = userProfiles.filter(
            (userProfile) =>
              userProfile.applicationId === this.env.auth.applicationId &&
              userProfile.companyId === companyId &&
              !!userProfile.roles.find((role) => role.id === this.env.auth.defaultZonarRole) &&
              userProfile.status === 'ACTIVE'
          );

          const allDivisionsFromUserProfilesSet = new Set();

          selectedProfiles.map((profile) => {
            profile.divisions.forEach((division) => allDivisionsFromUserProfilesSet.add(division));
          });

          const allDivisionsFromUserProfilesArray = Array.from(allDivisionsFromUserProfilesSet);
          return allDivisionsFromUserProfilesArray;
        }
      })
    );
  }

  /**
   * Get the LEGACY divisions list from selected company
   *
   * @param companyId the currently selected company on the UI
   */
  private getLegacyCompanyDivisionsByCompanyId(companyId: string): Observable<string[]> {
    return this._permissionsService.getCompanyDivisions().pipe(
      filter((divisionMap) => !!divisionMap),
      map((divisionMap) => {
        return this.convertToLegacyDivisions(companyId, divisionMap);
      })
    );
  }

  /**
   * Logic filter legacy division
   *
   * @param companyId the currently selected company on the UI
   * @param divisionMap The all division that built into divisionId:divisionObject before
   */
  private convertToLegacyDivisions(companyId, divisionMap): string[] {
    const companyDivisions = (Object.values(divisionMap)[0] || []) as IDivision[];
    return companyDivisions.reduce((acc: string[], division: IDivision) => {
      if (division.companyId === companyId && division.type === 'LEGACY' && division.status === 'ACTIVE') {
        acc.push(division.id);
      }
      return acc;
    }, []) as string[];
  }
}
