import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { AdminService } from '@app/services/admin.service';
import { AlertMetadata } from '@app/components/alerts-list/alerts-list.component';
import { AssetApiHttpService } from '@app/services/asset-api.http.service';
import { CompanyService } from '@app/services/company-store.service';
import { SubscriptionApiHttpService } from '@app/services/subscription-api.http.service';
import { environment } from '@environments/environment';
import { FeatureToggleService } from '@app/services/feature-toggle.service';

@Component({
  selector: 'app-my-alerts.page',
  templateUrl: './my-alerts.page.component.html',
  styleUrls: ['./my-alerts.page.component.scss']
})
export class MyAlertsPageComponent implements OnInit, OnDestroy {
  alertsLink = environment.activityFeedBase;
  alertsManagementLink = '/';
  isAdmin$ = this._adminService.isAnyAdmin$;

  alertMetadata: AlertMetadata = null;
  private _onDestroy$: Subject<void> = new Subject<void>();
  isPushNotificationsFeatureDisabled$: Observable<boolean> = this._featureToggleService.valueForKey('push-notifications').pipe(
    map((value) => value === false),
    takeUntil(this._onDestroy$)
  );

  constructor(
    private _adminService: AdminService,
    private _companyService: CompanyService,
    private _subscriptionService: SubscriptionApiHttpService,
    private _assetService: AssetApiHttpService,
    private _featureToggleService: FeatureToggleService
  ) {}

  ngOnInit() {
    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        switchMap((company) => {
          this.alertMetadata = null;

          return combineLatest([
            this._subscriptionService.getMyAlertSubscriptions(company.value),
            this._subscriptionService.getAlertTypesFromCache(),
            this._assetService.getAssetsFromCache().pipe(
              startWith([]),
              map((allAssets) => allAssets?.length || 0),
              catchError(() => of(0))
            )
          ]);
        }),
        map(([alerts, alertTypes, assetsTotal]) => ({ alerts, alertTypes, assetsTotal })),
        takeUntil(this._onDestroy$)
      )
      .subscribe((alertMetadata) => {
        this.alertMetadata = alertMetadata;
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}
